var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-center" }, [
    _vm.userCreated
      ? _c(
          "div",
          { staticClass: "flex w-full flex-col bg-white p-10 lg:w-1/2" },
          [
            _c(
              "div",
              { staticClass: "mx-auto flex items-center" },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-green mr-3",
                  attrs: { icon: "check-circle", size: "3x" },
                }),
                _c("h1", { staticClass: "text-center text-xl font-bold" }, [
                  _vm._v(" User successfully invited! "),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-auto mt-8" },
              [
                _c("FormSectionButton", {
                  attrs: { title: "Invite another user", color: "peach" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.resetValues()
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _c(
          "div",
          { staticClass: "flex w-full flex-col bg-white p-10 lg:w-1/2" },
          [
            _c(
              "div",
              [
                _c("FormSectionHeader", {
                  attrs: {
                    title: _vm.showSSN
                      ? "Basic information"
                      : "Email and phone number",
                    index: 0,
                    "is-active": _vm.activeSection === 0,
                    "has-error": _vm.errors.any("section-0"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToSection(0)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSection === 0,
                        expression: "activeSection === 0",
                      },
                    ],
                    staticClass: "flex",
                  },
                  [
                    _c("div", { staticClass: "mx-10 border" }),
                    _c("div", { staticClass: "w-full" }, [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("base-label", { attrs: { for: "email" } }, [
                            _vm._v(" Email address "),
                          ]),
                          _c("secondary-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'",
                              },
                            ],
                            attrs: {
                              id: "email",
                              "data-vv-scope": "section-0",
                              placeholder: "Email address",
                              name: "email",
                              type: "email",
                              disabled: _vm.userExists,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c("p", { staticClass: "text-red text-sm" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.errors.first("section-0.email")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("base-label", { attrs: { for: "phone" } }, [
                            _vm._v(" Phone number "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col md:flex-row" },
                            [
                              _c(
                                "div",
                                { staticClass: "mr-4 w-full md:w-1/3" },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.country_code_id,
                                          expression: "country_code_id",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "my-1 w-full rounded border py-3",
                                      attrs: {
                                        "data-vv-scope": "section-0",
                                        "data-vv-as": "country code",
                                        name: "country_code_id",
                                        disabled:
                                          _vm.userExists ||
                                          !_vm.countryCodes ||
                                          !_vm.countryCodes.length,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.country_code_id = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                      },
                                    },
                                    [
                                      (!_vm.countryCodes ||
                                        !_vm.countryCodes.length) &&
                                      (!_vm.user || !_vm.user.length)
                                        ? _c(
                                            "option",
                                            { domProps: { value: null } },
                                            [_vm._v(" Loading... ")]
                                          )
                                        : _vm._l(
                                            _vm.countryCodes,
                                            function (country) {
                                              return _c(
                                                "option",
                                                {
                                                  key: country.id,
                                                  domProps: {
                                                    value: country.id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "+" +
                                                          country.code +
                                                          " (" +
                                                          country.country +
                                                          ")"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                  _c("p", { staticClass: "text-red text-sm" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.errors.first(
                                            "section-0.country_code_id"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  _c("secondary-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|integer",
                                        expression: "'required|integer'",
                                      },
                                    ],
                                    attrs: {
                                      id: "mobile_phone",
                                      "data-vv-scope": "section-0",
                                      "data-vv-as": "mobile phone",
                                      placeholder: "Phone number",
                                      name: "mobile_phone",
                                      type: "tel",
                                      disabled: _vm.userExists,
                                    },
                                    model: {
                                      value: _vm.mobile_phone,
                                      callback: function ($$v) {
                                        _vm.mobile_phone = $$v
                                      },
                                      expression: "mobile_phone",
                                    },
                                  }),
                                  _c("p", { staticClass: "text-red text-sm" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.errors.first(
                                            "section-0.mobile_phone"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.showSSN
                        ? _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c(
                                "base-label",
                                { attrs: { for: "social_security_number" } },
                                [_vm._v(" Social security number ")]
                              ),
                              _c("secondary-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "social_security_number",
                                  "data-vv-scope": "section-0",
                                  "data-vv-as": "social security number",
                                  placeholder: "Social security number",
                                  name: "social_security_number",
                                  type: _vm.userExists ? "password" : "text",
                                  disabled: _vm.userExists,
                                },
                                model: {
                                  value: _vm.social_security_number,
                                  callback: function ($$v) {
                                    _vm.social_security_number = $$v
                                  },
                                  expression: "social_security_number",
                                },
                              }),
                              _c("p", { staticClass: "text-red text-sm" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.errors.first(
                                        "section-0.social_security_number"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mb-4 flex items-center" },
                        [
                          _c("FormSectionErrorMessage", {
                            attrs: { text: _vm.errorMessages["section-0"] },
                          }),
                          _c("FormSectionButton", {
                            staticClass: "ml-auto",
                            attrs: { loading: _vm.checkingUserExists },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.checkUserExists()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("FormSectionHeader", {
                  attrs: {
                    title: _vm.showSSN ? "Name" : "Name and details",
                    index: 1,
                    "is-active": _vm.activeSection === 1,
                    "has-error": _vm.errors.any("section-1"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToSection(1)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSection === 1,
                        expression: "activeSection === 1",
                      },
                    ],
                    staticClass: "flex",
                  },
                  [
                    _c("div", { staticClass: "mx-10 border" }),
                    _c("div", { staticClass: "w-full" }, [
                      _vm.userExists
                        ? _c(
                            "div",
                            {
                              staticClass: "mb-8 rounded bg-peach-light p-4",
                              attrs: { role: "alert" },
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "mr-2" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "info-circle" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    " A user account already exists, continue to invite the user to this clinic. You can still edit some information. "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("base-label", { attrs: { for: "first_name" } }, [
                            _vm._v(" First name "),
                          ]),
                          _c("secondary-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "first_name",
                              "data-vv-scope": "section-1",
                              "data-vv-as": "first name",
                              placeholder: "First name",
                              name: "first_name",
                              type: "text",
                              disabled: _vm.userExists,
                            },
                            model: {
                              value: _vm.first_name,
                              callback: function ($$v) {
                                _vm.first_name = $$v
                              },
                              expression: "first_name",
                            },
                          }),
                          _c("p", { staticClass: "text-red text-sm" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.errors.first("section-1.first_name")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("base-label", { attrs: { for: "last_name" } }, [
                            _vm._v(" Last name "),
                          ]),
                          _c("secondary-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "last_name",
                              "data-vv-scope": "section-1",
                              "data-vv-as": "last name",
                              placeholder: "Last name",
                              name: "last_name",
                              type: "text",
                              disabled: _vm.userExists,
                            },
                            model: {
                              value: _vm.last_name,
                              callback: function ($$v) {
                                _vm.last_name = $$v
                              },
                              expression: "last_name",
                            },
                          }),
                          _c("p", { staticClass: "text-red text-sm" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.errors.first("section-1.last_name")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("base-label", { attrs: { for: "display_name" } }, [
                            _vm._v(
                              " Display name (leave blank for first + last name) "
                            ),
                          ]),
                          _c("secondary-input", {
                            attrs: {
                              id: "display_name",
                              "data-vv-scope": "section-1",
                              "data-vv-as": "display name",
                              placeholder: "Display name",
                              name: "display_name",
                              type: "text",
                            },
                            model: {
                              value: _vm.display_name,
                              callback: function ($$v) {
                                _vm.display_name = $$v
                              },
                              expression: "display_name",
                            },
                          }),
                          _c("p", { staticClass: "text-red text-sm" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.errors.first("section-1.display_name")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      !_vm.showSSN
                        ? _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("base-label", { attrs: { for: "birthday" } }, [
                                _vm._v(" Birthdate "),
                              ]),
                              _c("secondary-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "birthday",
                                  "data-vv-scope": "section-1",
                                  placeholder: "Birthdate",
                                  name: "birthday",
                                  type: "date",
                                  disabled: _vm.userExists,
                                },
                                model: {
                                  value: _vm.birthday,
                                  callback: function ($$v) {
                                    _vm.birthday = $$v
                                  },
                                  expression: "birthday",
                                },
                              }),
                              _c("p", { staticClass: "text-red text-sm" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.errors.first("section-1.birthday")
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mb-4 flex items-center" },
                        [
                          _c("FormSectionErrorMessage", {
                            attrs: { text: _vm.errorMessages["section-1"] },
                          }),
                          _c("FormSectionButton", {
                            staticClass: "ml-auto",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.goToSection(2)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("FormSectionHeader", {
                  attrs: {
                    title: "Animal types",
                    index: 2,
                    "is-active": _vm.activeSection === 2,
                    "has-error": _vm.errors.any("section-2"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToSection(2)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSection === 2,
                        expression: "activeSection === 2",
                      },
                    ],
                    staticClass: "flex",
                  },
                  [
                    _c("div", { staticClass: "mx-10 border" }),
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _vm._l(_vm.animalTypes, function (animalType) {
                          return _c(
                            "div",
                            {
                              key: animalType.id,
                              staticClass: "flex items-center",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.animal_type_ids,
                                    expression: "animal_type_ids",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "mb-2 mr-4",
                                attrs: {
                                  id: "animal_type_ids-" + animalType.id,
                                  "data-vv-scope": "section-2",
                                  "data-vv-as": "animal type",
                                  type: "checkbox",
                                  name: "animal_type_ids",
                                },
                                domProps: {
                                  value: animalType.id,
                                  checked: Array.isArray(_vm.animal_type_ids)
                                    ? _vm._i(
                                        _vm.animal_type_ids,
                                        animalType.id
                                      ) > -1
                                    : _vm.animal_type_ids,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.animal_type_ids,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = animalType.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.animal_type_ids = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.animal_type_ids = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.animal_type_ids = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "base-label",
                                {
                                  attrs: {
                                    for: "animal_type_ids-" + animalType.id,
                                  },
                                },
                                [_vm._v(" " + _vm._s(animalType.name) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        _c("p", { staticClass: "text-red text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.errors.first("section-2.animal_type_ids")
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mb-4 flex items-center" },
                          [
                            _c("FormSectionErrorMessage", {
                              attrs: { text: _vm.errorMessages["section-2"] },
                            }),
                            _c("FormSectionButton", {
                              staticClass: "ml-auto",
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.goToSection(3)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("FormSectionHeader", {
                  attrs: {
                    title: "Permissions",
                    index: 3,
                    "is-active": _vm.activeSection === 3,
                    "has-error": _vm.errors.any("section-3"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToSection(3)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSection === 3,
                        expression: "activeSection === 3",
                      },
                    ],
                    staticClass: "flex",
                  },
                  [
                    _c("div", { staticClass: "mx-10 border" }),
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "flex max-h-full items-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-switch max-h-full" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.is_admin,
                                      expression: "is_admin",
                                    },
                                  ],
                                  staticClass: "form-switch-checkbox",
                                  attrs: {
                                    id: "is_admin",
                                    type: "checkbox",
                                    name: "is_admin",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.is_admin)
                                      ? _vm._i(_vm.is_admin, null) > -1
                                      : _vm.is_admin,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.is_admin,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_admin = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_admin = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_admin = $$c
                                      }
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "form-switch-label display-none",
                                  attrs: { for: "is_admin" },
                                }),
                              ]
                            ),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "text-sm font-semibold text-gray-900",
                                attrs: { for: "is_admin" },
                              },
                              [_vm._v(" Admin ")]
                            ),
                          ]
                        ),
                      ]),
                      _c("p", { staticClass: "text-red text-sm" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.errors.first("section-3.is_admin")) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("hr", { staticClass: "-mx-10 my-8 border" }),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("FormSectionErrorMessage", {
                      attrs: { text: _vm.errorMessages["section-3"] },
                    }),
                    _c("FormSectionButton", {
                      staticClass: "ml-auto",
                      attrs: {
                        title: "Send invite",
                        color: "peach",
                        disabled: _vm.activeSection !== 3 || _vm.loading,
                        loading: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }