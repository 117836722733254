<template>
  <h3
    class="text-xl font-bold my-4 flex items-center"
    :class="{'cursor-pointer': !isActive}"
  >
    <div
      class="flex h-10 items-center justify-center mx-5 rounded-full w-10"
      :class="hasError ? 'bg-red-400' : (isActive ? 'bg-gray-700 text-white' : 'bg-gray-light')"
    >
      {{ index + 1 }}
    </div>
    <span>{{ title }}</span>
  </h3>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
