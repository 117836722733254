var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h3",
    {
      staticClass: "text-xl font-bold my-4 flex items-center",
      class: { "cursor-pointer": !_vm.isActive },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex h-10 items-center justify-center mx-5 rounded-full w-10",
          class: _vm.hasError
            ? "bg-red-400"
            : _vm.isActive
            ? "bg-gray-700 text-white"
            : "bg-gray-light",
        },
        [_vm._v(" " + _vm._s(_vm.index + 1) + " ")]
      ),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }