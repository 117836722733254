var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "py-3 px-12 text-white rounded",
      class:
        "bg-" +
        _vm.color +
        " " +
        (_vm.disabled || _vm.loading
          ? "opacity-75 cursor-not-allowed"
          : "hover:bg-" + _vm.color + "-darker"),
      attrs: { disabled: _vm.disabled || _vm.loading },
    },
    [
      _vm.loading
        ? _c("base-spinner")
        : _c("span", [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }