<template>
  <div class="flex justify-center">
    <div v-if="userCreated" class="flex w-full flex-col bg-white p-10 lg:w-1/2">
      <div class="mx-auto flex items-center">
        <font-awesome-icon
          icon="check-circle"
          class="text-green mr-3"
          size="3x"
        />
        <h1 class="text-center text-xl font-bold">
          User successfully invited!
        </h1>
      </div>

      <div class="mx-auto mt-8">
        <FormSectionButton
          title="Invite another user"
          color="peach"
          @click.native="resetValues()"
        />
      </div>
    </div>
    <div v-else class="flex w-full flex-col bg-white p-10 lg:w-1/2">
      <div>
        <FormSectionHeader
          :title="showSSN ? 'Basic information' : 'Email and phone number'"
          :index="0"
          :is-active="activeSection === 0"
          :has-error="errors.any('section-0')"
          @click.native="goToSection(0)"
        />

        <div v-show="activeSection === 0" class="flex">
          <div class="mx-10 border" />
          <div class="w-full">
            <div class="mb-4">
              <base-label for="email"> Email address </base-label>
              <secondary-input
                id="email"
                v-model="email"
                v-validate="'required|email'"
                data-vv-scope="section-0"
                placeholder="Email address"
                name="email"
                type="email"
                :disabled="userExists"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-0.email') }}
              </p>
            </div>

            <div class="mb-4">
              <base-label for="phone"> Phone number </base-label>
              <div class="flex flex-col md:flex-row">
                <div class="mr-4 w-full md:w-1/3">
                  <select
                    v-model="country_code_id"
                    v-validate="'required'"
                    data-vv-scope="section-0"
                    data-vv-as="country code"
                    name="country_code_id"
                    class="my-1 w-full rounded border py-3"
                    :disabled="
                      userExists || !countryCodes || !countryCodes.length
                    "
                  >
                    <option
                      v-if="
                        (!countryCodes || !countryCodes.length) &&
                        (!user || !user.length)
                      "
                      :value="null"
                    >
                      Loading...
                    </option>
                    <option
                      v-for="country in countryCodes"
                      v-else
                      :key="country.id"
                      :value="country.id"
                    >
                      {{ `+${country.code} (${country.country})` }}
                    </option>
                  </select>
                  <p class="text-red text-sm">
                    {{ errors.first('section-0.country_code_id') }}
                  </p>
                </div>
                <div class="flex-1">
                  <secondary-input
                    id="mobile_phone"
                    v-model="mobile_phone"
                    v-validate="'required|integer'"
                    data-vv-scope="section-0"
                    data-vv-as="mobile phone"
                    placeholder="Phone number"
                    name="mobile_phone"
                    type="tel"
                    :disabled="userExists"
                  />
                  <p class="text-red text-sm">
                    {{ errors.first('section-0.mobile_phone') }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="showSSN" class="mb-4">
              <base-label for="social_security_number">
                Social security number
              </base-label>
              <secondary-input
                id="social_security_number"
                v-model="social_security_number"
                v-validate="'required'"
                data-vv-scope="section-0"
                data-vv-as="social security number"
                placeholder="Social security number"
                name="social_security_number"
                :type="userExists ? 'password' : 'text'"
                :disabled="userExists"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-0.social_security_number') }}
              </p>
            </div>

            <div class="mb-4 flex items-center">
              <FormSectionErrorMessage :text="errorMessages['section-0']" />

              <FormSectionButton
                class="ml-auto"
                :loading="checkingUserExists"
                @click.native="checkUserExists()"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <FormSectionHeader
          :title="showSSN ? 'Name' : 'Name and details'"
          :index="1"
          :is-active="activeSection === 1"
          :has-error="errors.any('section-1')"
          @click.native="goToSection(1)"
        />

        <div v-show="activeSection === 1" class="flex">
          <div class="mx-10 border" />
          <div class="w-full">
            <div
              v-if="userExists"
              class="mb-8 rounded bg-peach-light p-4"
              role="alert"
            >
              <div class="flex">
                <div class="mr-2">
                  <font-awesome-icon icon="info-circle" />
                </div>
                <p class="text-sm">
                  A user account already exists, continue to invite the user to
                  this clinic. You can still edit some information.
                </p>
              </div>
            </div>

            <div class="mb-4">
              <base-label for="first_name"> First name </base-label>
              <secondary-input
                id="first_name"
                v-model="first_name"
                v-validate="'required'"
                data-vv-scope="section-1"
                data-vv-as="first name"
                placeholder="First name"
                name="first_name"
                type="text"
                :disabled="userExists"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-1.first_name') }}
              </p>
            </div>

            <div class="mb-4">
              <base-label for="last_name"> Last name </base-label>
              <secondary-input
                id="last_name"
                v-model="last_name"
                v-validate="'required'"
                data-vv-scope="section-1"
                data-vv-as="last name"
                placeholder="Last name"
                name="last_name"
                type="text"
                :disabled="userExists"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-1.last_name') }}
              </p>
            </div>

            <div class="mb-4">
              <base-label for="display_name">
                Display name (leave blank for first + last name)
              </base-label>
              <secondary-input
                id="display_name"
                v-model="display_name"
                data-vv-scope="section-1"
                data-vv-as="display name"
                placeholder="Display name"
                name="display_name"
                type="text"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-1.display_name') }}
              </p>
            </div>

            <div v-if="!showSSN" class="mb-4">
              <base-label for="birthday"> Birthdate </base-label>
              <secondary-input
                id="birthday"
                v-model="birthday"
                v-validate="'required'"
                data-vv-scope="section-1"
                placeholder="Birthdate"
                name="birthday"
                type="date"
                :disabled="userExists"
              />
              <p class="text-red text-sm">
                {{ errors.first('section-1.birthday') }}
              </p>
            </div>

            <div class="mb-4 flex items-center">
              <FormSectionErrorMessage :text="errorMessages['section-1']" />

              <FormSectionButton
                class="ml-auto"
                @click.native="goToSection(2)"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <FormSectionHeader
          title="Animal types"
          :index="2"
          :is-active="activeSection === 2"
          :has-error="errors.any('section-2')"
          @click.native="goToSection(2)"
        />

        <div v-show="activeSection === 2" class="flex">
          <div class="mx-10 border" />
          <div class="w-full">
            <div
              v-for="animalType in animalTypes"
              :key="animalType.id"
              class="flex items-center"
            >
              <input
                :id="`animal_type_ids-${animalType.id}`"
                v-model="animal_type_ids"
                v-validate="'required'"
                data-vv-scope="section-2"
                data-vv-as="animal type"
                type="checkbox"
                name="animal_type_ids"
                class="mb-2 mr-4"
                :value="animalType.id"
              />
              <base-label :for="`animal_type_ids-${animalType.id}`">
                {{ animalType.name }}
              </base-label>
            </div>
            <p class="text-red text-sm">
              {{ errors.first('section-2.animal_type_ids') }}
            </p>

            <div class="mb-4 flex items-center">
              <FormSectionErrorMessage :text="errorMessages['section-2']" />

              <FormSectionButton
                class="ml-auto"
                @click.native="goToSection(3)"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <FormSectionHeader
          title="Permissions"
          :index="3"
          :is-active="activeSection === 3"
          :has-error="errors.any('section-3')"
          @click.native="goToSection(3)"
        />

        <div v-show="activeSection === 3" class="flex">
          <div class="mx-10 border" />
          <div class="w-full">
            <div class="mb-4">
              <div class="flex max-h-full items-center">
                <div class="form-switch max-h-full">
                  <input
                    id="is_admin"
                    v-model="is_admin"
                    type="checkbox"
                    name="is_admin"
                    class="form-switch-checkbox"
                  />
                  <label
                    class="form-switch-label display-none"
                    for="is_admin"
                  />
                </div>
                <label
                  class="text-sm font-semibold text-gray-900"
                  for="is_admin"
                >
                  Admin
                </label>
              </div>
            </div>
            <p class="text-red text-sm">
              {{ errors.first('section-3.is_admin') }}
            </p>
          </div>
        </div>

        <hr class="-mx-10 my-8 border" />

        <div class="flex items-center">
          <FormSectionErrorMessage :text="errorMessages['section-3']" />

          <FormSectionButton
            class="ml-auto"
            title="Send invite"
            color="peach"
            :disabled="activeSection !== 3 || loading"
            :loading="loading"
            @click.native="submit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters, mapActions } from 'vuex';
import FormSectionHeader from '@/components/admin/invite-user/FormSectionHeader';
import FormSectionErrorMessage from '@/components/admin/invite-user/FormSectionErrorMessage';
import FormSectionButton from '@/components/admin/invite-user/FormSectionButton';
import DigitalClinicApi from '@/api/modules/admin/digital-clinic';

// TODO: store these somewhere else
const UK_COUNTRY_ID = 826;
const DE_COUNTRY_ID = 276;

export default {
  components: {
    FormSectionHeader,
    FormSectionErrorMessage,
    FormSectionButton,
  },
  data() {
    return {
      loading: false,
      first_name: '',
      last_name: '',
      display_name: '',
      email: '',
      country_code_id: null,
      mobile_phone: '',
      social_security_number: '',
      birthday: '',
      animal_type_ids: [],
      is_admin: false,

      existingUser: null,
      checkingUserExists: false,
      userExists: false,
      userCreated: false,
      errorMessages: {},

      activeSection: 0,
    };
  },
  computed: {
    ...mapGetters('user', { user: 'getUser' }),
    ...mapGetters('admin/digital-clinic', {
      digitalClinic: 'getDigitalClinic',
      digitalClinicId: 'getDigitalClinicId',
    }),
    ...mapGetters('animal', { animalTypes: 'getAnimalTypes' }),
    ...mapGetters('country', { countryCodes: 'fetchCountryCodes' }),
    countryCodeList() {
      return this.countryCodes.map(code => {
        return {
          id: code.id,
          name: code.country,
          code: code.code,
        };
      });
    },
    showSSN() {
      return (
        this.digitalClinic &&
        this.digitalClinic.country_id &&
        this.digitalClinic.country_id !== UK_COUNTRY_ID &&
        this.digitalClinic.country_id !== DE_COUNTRY_ID
      );
    },
  },
  async mounted() {
    if (!this.countryCodes.length) {
      await this.fetchCountryCodes();
    }

    if (this.user && this.user.country_code) {
      this.country_code_id = this.user.country_code.id;
    }

    if (!this.animalTypes.length) {
      this.fetchAnimalTypes();
    }
  },

  methods: {
    ...mapActions('animal', ['fetchAnimalTypes']),
    ...mapActions('country', ['fetchCountryCodes']),
    async goToSection(section) {
      if (section === this.activeSection) {
        return;
      }

      if (this.activeSection === 0) {
        this.checkUserExists(section);
        return;
      }

      const valid = await this.$validator.validate(
        `section-${this.activeSection}.*`
      );

      if (valid) {
        this.activeSection = section;
        this.errorMessages[`section-${section}`] = '';
      }
    },
    async checkUserExists(nextSection = 1) {
      this.userExists = false;

      const scope = 'section-0';
      const valid = await this.$validator.validate(`${scope}.*`);

      if (!valid) {
        return;
      }

      this.checkingUserExists = true;

      const userExistsQuery = {
        email: this.email,
        mobile_phone: this.mobile_phone,
        country_code_id: this.country_code_id,
      };

      if (this.showSSN) {
        userExistsQuery.social_security_number = this.social_security_number;
      }

      DigitalClinicApi.checkUserExists({
        digitalClinicId: this.digitalClinicId,
        data: userExistsQuery,
      })
        .then(res => {
          const user = res && res.data && res.data.user;

          if (user) {
            if (user.digital_clinics) {
              const userBelongsToClinic = user.digital_clinics.find(d => {
                return d.id === this.digitalClinicId;
              });

              if (userBelongsToClinic) {
                this.errorMessages[scope] = 'User already belongs to clinic';
                return;
              }
            }

            this.email = user.email;
            this.display_name = user.display_name;
            this.first_name = user.first_name;
            this.last_name = user.last_name;
            this.mobile_phone = user.mobile_phone || user.phone;
            this.country_code_id = user.country_code_id;

            if (!this.showSSN) {
              this.birthday = user.birthday
                ? format(new Date(user.birthday), 'yyyy-MM-dd')
                : this.birthday;
            }

            this.userExists = true;
          }

          this.existingUser = user;

          this.activeSection = nextSection;
        })
        .catch(e => {
          const errors =
            e && e.response && e.response.data && e.response.data.errors;

          if (typeof errors === 'object') {
            Object.keys(errors).forEach(field => {
              this.errors.add({
                field,
                scope,
                msg: 'The given data is invalid',
              });
            });
          }
        })
        .finally(() => {
          this.checkingUserExists = false;
        });
    },
    async submit() {
      const valid = await this.$validator.validate();

      if (valid) {
        this.loading = true;

        await (this.userExists
          ? this.updateAndInviteUser()
          : this.createAndInviteUser());

        this.loading = false;
      } else {
        this.errorMessages['section-3'] = 'Invalid fields';
      }
    },
    updateAndInviteUser() {
      const data = {
        display_name: this.display_name,
        is_admin: this.is_admin,
        animal_type_ids: this.animal_type_ids,
      };

      return DigitalClinicApi.updateUser({
        digitalClinicId: this.digitalClinicId,
        userId: this.existingUser.id,
        data,
      })
        .then(res => {
          console.log('User updated', res);
          this.userCreated = true;
        })
        .catch(e => {
          this.handleErrorResponse(e);
        });
    },
    createAndInviteUser() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        display_name: this.display_name,
        email: this.email,
        mobile_phone: this.mobile_phone,
        country_code_id: this.country_code_id,
        country_id: this.digitalClinic.country_id,
        is_admin: this.is_admin,
        animal_type_ids: this.animal_type_ids,
      };

      if (this.showSSN) {
        data.social_security_number = this.social_security_number;
      } else {
        data.birthday = this.birthday;
      }

      return DigitalClinicApi.createUser({
        digitalClinicId: this.digitalClinicId,
        data,
      })
        .then(res => {
          console.log('User created', res);
          this.userCreated = true;
        })
        .catch(e => {
          this.handleErrorResponse(e);
        });
    },
    handleErrorResponse(e) {
      if (!e || !e.response || !e.response.data || !e.response.data.errors) {
        this.errorMessages['section-3'] =
          'Something went wrong. Please try again later.';
        return;
      }

      const invalidFields = e.response.data.errors;
      let firstInvalidSection = 0;

      Object.keys(invalidFields).forEach(field => {
        const fieldElement = this.$validator.fields.find({
          name: field,
        });

        if (!fieldElement) {
          return;
        }

        this.errors.add({
          field,
          scope: fieldElement.scope,
          msg: invalidFields[field].join(' '),
        });

        // Show first section with invalid fields.
        const sectionIndex = +fieldElement.scope.replace('section-', '');

        firstInvalidSection =
          sectionIndex < firstInvalidSection
            ? sectionIndex
            : firstInvalidSection;
      });

      this.activeSection = firstInvalidSection;
      this.errorMessages['section-3'] = e.response.data.message;
    },
    resetValues() {
      this.first_name = '';
      this.last_name = '';
      this.display_name = '';
      this.email = '';
      this.mobile_phone = '';
      this.social_security_number = '';
      this.birthday = '';
      this.is_admin = false;
      this.animal_type_ids = [];

      this.userCreated = false;
      this.userExists = false;
      this.existingUser = null;

      this.errorMessages = {};

      this.activeSection = 0;

      this.$validator.reset();
    },
  },
};
</script>
