<template>
  <button
    class="py-3 px-12 text-white rounded"
    :class="`bg-${color} ` + ((disabled || loading) ? `opacity-75 cursor-not-allowed` : `hover:bg-${color}-darker`)"
    :disabled="disabled || loading"
  >
    <base-spinner v-if="loading" />
    <span v-else>{{ title }}</span>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Next',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
